import React from 'react';
import logo from './kangarooLogo.png';
import './App.css';

function App() {
  return (
      <div className="App">
            <div style={{ paddingTop: 100 }}>
            <a className="App-logo"  href="https://calendly.com/productkangaroo/">hop into your next career</a>     
             </div>
            {/* <div>
              <img src={logo} className="App-logo" alt="logo" />
            </div> */}
      </div>
  );
}

export default App;